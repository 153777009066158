
.btn.btn-checkbox {
  background-color: #26314800 !important;
  background-image: -webkit-gradient(
    linear,
    right top, left bottom,
    from(#34467500),
    color-stop(#26314800),
    to(#34467500)
  ) !important;
  background-image: linear-gradient(
    to bottom left,
    #34467500,
    #26314800,
    #34467500
  ) !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn.btn-checkbox.active {
  background-color: #26314800 !important;
  background-image: -webkit-gradient(
    linear,
    right top, left bottom,
    from(#34467500),
    color-stop(#26314800),
    to(#34467500)
  ) !important;
  background-image: linear-gradient(
    to bottom left,
    #34467500,
    #26314800,
    #34467500
  ) !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn.btn-checkbox:hover {
  background-color: #6c757d0f !important;
  background-image: -webkit-gradient(
    linear,
    right top, left bottom,
    from(#2e3b5f00),
    color-stop(#26314800),
    to(#4b5c8a)
  ) !important;
  background-image: linear-gradient(
    to bottom left,
    #2e3b5f00,
    #26314800,
    #4b5c8a
  ) !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.alert.alert-success button.close {
  top: 50%;
}
#btn-crear-ruta,
#btn-actualizar-ruta,
#btn-cancelar {
  margin-top: 30px;
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0) !important;
          transform: translate(0, 0) !important;
}
#modalEditarModulosDeUsuario
  > div
  > div
  > div.modal-body
  > div
  > div
  > form
  > div
  > select {
  color: black;
}
option {
  color: black !important;
}
